import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from "react-bootstrap"

import styles from "./index.module.css"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ArchivPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allArchivYaml {
        edges {
          node {
            reports {
              title
              path
            }
            headline
            raw
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <Layout>
      <SEO title="Archiv" lang="de" />
      <Container>
        <h1 className="h3">Archiv</h1>
        <hr className="featurette-divider" />
        <p>
          Hier in unserem Archiv finden sie alle älteren, nicht mehr ganz so
          aktuellen Berichte, z.B. von Mitgliederversammlungen und
          Wanderpokalschießen der letzten Jahre.
        </p>

        {data.allArchivYaml.edges.map(topic => {
          return (
            <div className={styles.topic}>
              <h5>{topic.node.headline}</h5>
              <ul>
                {topic.node.reports.map(report => {
                  return (
                    <li>
                      <Link to={`${topic.node.raw}/${report.path}`}>
                        {report.title}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </Container>
    </Layout>
  )
}

export default ArchivPage
